<template>
  <div class="container">
    <div class="row">
      <div class="col-12 benefits desktop">
        <div class="benefits__item">
          <img src="/img/benefits/cities.svg" height="32" width="32" alt="">
          <h3>800+ Destinations</h3>
          <p>
            Come tour with us, wherever <br class="desktop"> you go.<br class="desktop">
            From New York to Sydney. From Moscow to Buenos Aires. Fun & delight everywhere.
          </p>
        </div>
        <div class="benefits__item">
          <img v-lazy="'/img/benefits/low-price.svg'" height="32" width="32" alt="">
          <h3>Lowest price guaranteed</h3>
          <p>
            We will match any advertised price from the vendors or reseller websites.
          </p>
        </div>
        <div class="benefits__item">
          <img v-lazy="'/img/benefits/flexible.svg'" height="32" width="32" alt="">
          <h3>Flexible Booking</h3>
          <p>
            Your plans changed? No problem! Cancel most of our tours and activities (see terms on each product page)
          </p>
        </div>
        <div class="benefits__item" @click="showHelpModal">
          <img v-lazy="'/img/benefits/support.svg'" height="32" width="32" alt="Support button">
          <h3>Live Support</h3>
          <p></p>
        </div>
      </div>
    </div>
      <div class="col-12 benefits mobile">
        <client-only>
          <carousel
            v-if="isNuxtReady"
            :nav="false"
            :items="1"
            :center="true"
          >
            <div class="slide">
              <div class="benefits__item">
                <img v-lazy="'/img/benefits/cities.svg'"  height="32" alt="">
                <h3>800+ Destinations</h3>
                <p>
                  Come tour with us, wherever <br class="desktop"> you go.<br class="desktop">
                  From New York to Sydney. From Moscow to Buenos Aires. Fun & delight everywhere.
                </p>
              </div>
            </div>
            <div class="slide">
              <div class="benefits__item">
                <img v-lazy="'/img/benefits/low-price.svg'" height="32" alt="">
                <h3>Lowest price guaranteed</h3>
                <p>
                  We will match any advertised price from the vendors or reseller websites.
                </p>
              </div>
            </div>
            <div class="slide">
              <div class="benefits__item">
                <img v-lazy="'/img/benefits/flexible.svg'" height="32" alt="">
                <h3>Flexible Booking</h3>
                <p>
                  Your plans changed? No problem! Cancel most of our tours and activities (see terms on each product page)
                </p>
              </div>
            </div>
            <div class="slide">
              <div class="benefits__item" @click="showHelpModal">
                <img v-lazy="'/img/benefits/support.svg'" height="32" alt="">
                <h3>Live Support</h3>
                <p></p>
              </div>
            </div>
          </carousel>
        </client-only>
      </div>
    </div>
</template>

<style lang="scss" src="./benefits.scss"></style>

<script>
const carousel = () => window && window !== undefined ? import('vue-owl-carousel') : null
import helpModal from '@/mixins/help-modal.js'

export default {
  mixins: [helpModal],
  data() {
    return {
      isNuxtReady: false
    }
  },
  async mounted() {
    if (process.browser) {
      window.onNuxtReady(app => {
        this.isNuxtReady = true
        if (this.$route.hash === '#help') this.showHelpModal()
      })
    }
  },
  components: {
    carousel
  }
}
</script>
