<template>
  <section class="blog-section" v-if="posts && posts.length">
    <v-container>
      <h2 class="section-title" v-if="title">{{ title }}</h2>

      <div class="posts-list">
        <v-row>
          <v-col
            cols="12"
            sm="4"
            class="pt-0 pb-0"
            v-for="(post, index) in posts"
            :key="index"
            :index="index"
          >
            <div class="post-article" @click="goTo(post.slug)">
              <div class="post-img">
                <client-only>
                  <img v-lazy="post.feature_image" alt="" width="750" height="200" class="cover-img">
                </client-only>
              </div>

              <h3 class="post-title">{{ post.title }}</h3>

              <p class="post-text">{{ post.excerpt }}</p>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="more-articles" v-if="moreText">
        <v-btn
          outlined
          block
          x-large
          color="primary"
          class="more-articles-btn"
          target="_blank"
          :href="blogURL"
        >{{ moreText }}</v-btn>
      </div>
    </v-container>
  </section>
</template>

<script>

export default {
  props: {
    title: String,
    limit: Number,
    moreText: String,
    tag: String
  },
  data() {
    return {
      posts: [],
      blogURL: this.$config.blogURL
    }
  },
  methods: {
    goTo(slug) {
      window.open(this.blogURL + '/'+ slug, '_blank')
    },
    getPosts() {
      const filter = this.tag ? `&filter=tag:${this.tag}` : ''
      const url = `${this.blogURL}/ghost/api/v3/content/posts/?key=${this.$config.blogAPIKey}&limit=${this.limit || 3}${filter}`

      this.$axios.get(url).then(response => {
        this.posts = response.data && response.data.posts ? response.data.posts : []
      }).catch(error => {
        console.log(error)
      })
    },
  },
  mounted() {
    this.getPosts()
  }
}
</script>

<style src="./blog-posts.scss" lang="scss"></style>
